exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-ally-js": () => import("./../../../src/pages/ally.js" /* webpackChunkName: "component---src-pages-ally-js" */),
  "component---src-pages-coverage-js": () => import("./../../../src/pages/coverage.js" /* webpackChunkName: "component---src-pages-coverage-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ini-js": () => import("./../../../src/pages/INI.js" /* webpackChunkName: "component---src-pages-ini-js" */),
  "component---src-pages-ona-js": () => import("./../../../src/pages/ona.js" /* webpackChunkName: "component---src-pages-ona-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-services-creative-content-js": () => import("./../../../src/pages/services/creative-content.js" /* webpackChunkName: "component---src-pages-services-creative-content-js" */),
  "component---src-pages-services-new-media-workshops-js": () => import("./../../../src/pages/services/new-media-workshops.js" /* webpackChunkName: "component---src-pages-services-new-media-workshops-js" */),
  "component---src-pages-services-news-wire-js": () => import("./../../../src/pages/services/news-wire.js" /* webpackChunkName: "component---src-pages-services-news-wire-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-vngler-js": () => import("./../../../src/pages/vngler.js" /* webpackChunkName: "component---src-pages-vngler-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/frontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

